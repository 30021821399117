<template>
  <SceneSetup
    class="earth"
    ref="sceneContainer"
    :pageId="pageId"
    :content="content"
    :scenes="scenes"
    :clickedSphere="clickedSphere"
    :currentSceneIndex="currentSceneIndex"
    :loaded="loaded"
    @onSwipeStart="onSwipeStart"
    @onSwipe="onSwipe"
    @closeDetails="closeDetails"
    @setNewScene="changeSceneFromMenu"
    @toProject="navigateToProject"
    @toggleInteraction="setInteract"
  />
</template>

<script>
import { threeSetup } from "@/mixins/three-setup";
import { Color, ReinhardToneMapping } from "three";
import SceneSetup from "@/components/UI/SceneSetup.vue";

export default {
  components: { SceneSetup },
  mixins: [threeSetup],
  data() {
    // All the data here should specific to the Terra scenes
    return {
      pageId: "earth",
      scenesConfig: [
        {
          folder: "rock",
          scale: 8,
          position: { y: -3.25, x: 0.4 },
          rotation: {
            x: Math.PI * 1.77,
            y: Math.PI * 1.54,
            z: 10
          },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: -0.5 },
          ringGroupRotation: { x: Math.PI * 0.42 }
        },
        {
          folder: "plant",
          scale: 0.02,
          position: { y: -7.5, z: -0.5, x: 0.75 },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: 0.1 },
          ringGroupRotation: { x: Math.PI * 0.65 }
        },
        {
          folder: "jar",
          scale: 0.025,
          position: { y: -1.5, z: -3.25, x: 2 },
          rotation: {
            z: Math.PI * -0.2,
            y: Math.PI * -0.3
          },
          sphereFocusIndex: 0,
          ringGroupPosition: { y: 0.1 },
          ringGroupRotation: { x: Math.PI * 0.6 }
        }
      ],
      gradientColor: {
        color1: {
          value: new Color("hsl(35, 40%, 90%)")
        },
        color2: {
          value: new Color("hsl(35, 60%, 75%)")
        },
        color3: {
          value: new Color("hsl(35, 80%, 100%)")
        },
        step1: {
          value: 0.1
        },
        step2: {
          value: 0.5
        },
        step3: {
          value: 1.2
        }
      },
      glowOptions: {
        opacity: {
          value: 1.0
        },
        color1: {
          value: new Color("hsl(45, 100%, 80%)")
        },
        color2: {
          value: new Color("hsl(50, 100%, 100%)")
        },
        step1: {
          value: 0.0
        },
        step2: {
          value: 1.3
        }
      },
      ringColor: "hsl(40, 57%, 25%)",
      lightColors: ["hsl(60, 50%, 50%)", "hsl(60, 20%, 50%)"],
      toneMapping: ReinhardToneMapping
    };
  },
  computed: {
    content() {
      return this.$t("earth");
    }
  },
  metaInfo() {
    return this.$t("share").earth;
  },
  created() {
    this.setIndexFromMenu(6);
  },

  methods: {
    setSmallScreenConf() {
      this.scenesConfig = this.scenesConfig.map(conf => {
        if (conf.folder === "rock") {
          conf.scale = 4;
          conf.position.y = -1;
          conf.ringGroupRotation = { x: Math.PI * 0.45 };
        }

        if (conf.folder === "plant") {
          conf.ringGroupRotation = { x: Math.PI * 0.6 };
        }

        if (conf.folder === "jar") {
          conf.scale = 0.016;

          conf.position.x = 0.75;
          conf.position.y = -1;
          conf.position.z = -1.25;
        }

        return conf;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.earth {
  // background: $bg-earth;
}
</style>
